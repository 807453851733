import React, { useState } from 'react'
import Select from 'react-select'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import without from 'lodash/without'

import RadioButton from '../common/RadioButton'
import { Input } from '../common/Input'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { emojiFor, Option, Species, uniqueCategoriesFor } from '../../lib/helpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { insertConditionAction } from '../../hasura/slices/conditions'

interface Props {
  setDisplayCreateConditionModal: (displayCreateConditionModal: boolean) => void
}

export default function CreateConditionModal(props: Props) {
  const dispatch = useDispatch()

  const [species, setSpecies] = useState<Species[]>([])
  const [displayName, setDisplayName] = useState<string | undefined>(undefined)
  const [category, setCategory] = useState<Option | null>(null)

  const { conditions }: ConsultationsState = useSelector(consultationsSelector)
  const { accessToken }: UsersState = useSelector(usersSelector)

  const toggleSpecies = (s: Species) => setSpecies(species.includes(s) ? without(species, s) : species.concat(s))

  const categories = uniqueCategoriesFor(conditions)

  const createCondition = async () => {
    await dispatch(
      insertConditionAction(accessToken, {
        display_name: displayName,
        species: species.join(),
        condition_category_id: category?.value,
      })
    )

    setDisplayName(undefined)
  }

  return (
    <Modal
      fade={false}
      centered
      autoFocus={false}
      isOpen
      className="position-relative"
      toggle={() => props.setDisplayCreateConditionModal(false)}
    >
      <ModalHeader>New Condition</ModalHeader>

      <ModalBody className="p-4">
        <Input
          className="mb-2"
          autoFocus
          onChange={(e: any) => setDisplayName(e.target.value)}
          placeholder="Name..."
          value={displayName || ''}
        />

        <div className="my-2">
          <RadioButton
            onClick={() => toggleSpecies(Species.Dog)}
            checked={species.includes(Species.Dog)}
            label={'Dogs ' + emojiFor(Species.Dog)}
          />

          <RadioButton
            onClick={() => toggleSpecies(Species.Cat)}
            checked={species.includes(Species.Cat)}
            label={'Cats ' + emojiFor(Species.Cat)}
          />
        </div>

        <Select
          className="react-select-container my-3"
          classNamePrefix="react-select"
          value={category}
          placeholder="Category"
          onChange={(option: any) => setCategory(option)}
          isClearable
          options={categories.map((c) => ({
            value: c.id,
            label: c.display_name,
          }))}
        />

        <Button
          onClick={createCondition}
          disabled={!displayName || !species.length || !category}
          className="button-w-m"
          color="primary"
        >
          Create
        </Button>
      </ModalBody>
    </Modal>
  )
}
